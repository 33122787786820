export function initShrinkyDinkHeading() {
	const headings = document.querySelectorAll('.block.shrinky-dink-heading.active');
	if(!headings) return;

	let threshold = 0.9;
	let options = {
		rootMargin: '-120px 0px 100000px',
		threshold,
	}

	let setInview = (entries, observer) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				entry.target.classList.add('in-view');
			}
			else {
				entry.target.classList.remove('in-view');
			}
		});
	}

	let observer = new IntersectionObserver(setInview, options);

	headings.forEach(heading => {
		observer.observe(heading);
	});
}