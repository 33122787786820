export function needsTabindexAdded(el) {
	return (
		(el.getAttribute('tabindex') == null) &&
		(el.getAttribute('onclick') == null) &&
		(el.getAttribute('href') == null)
	)
}

export function initTrackAnalyticsEvent() {
	window.setupTrackAnalyticsEventAfterLoad = setupTrackAnalyticsEvent;
	window.addEventListener('load', function() {
		this.setTimeout(setupTrackAnalyticsEventAfterLoad, 100);
	});
}

function setupTrackAnalyticsEvent() {
	window.trackAnalyticsEvent = function trackAnalyticsEvent({ event, target, additionalProperties }) {

		const $target = $(target);
		const eventProperties = $target.data("eventProperties") || false;
		const properties = (eventProperties) ? JSON.parse(decodeURIComponent(eventProperties)) : {};
		const href = $target.attr("href");
		const api = $target.data("api") || 'vwo-events';
		const eventTrigger = $target.data("eventTrigger");

		if (href) {
			properties.href = href;
			if (!properties.path) properties.path = new URL(href, location).pathname;
		}

		if(additionalProperties) $.extend(properties, additionalProperties);

		event = $.trim(event);
		if(api == 'vwo-events') { // VWO - Events
			window.VWO = window.VWO || [];
			VWO.event = VWO.event || function () {VWO.push(["event"].concat([].slice.call(arguments)))};
			VWO.event(event, properties);
		}
		else { // Analytics to Segment (Legacy)
			analytics.track(event, properties);
		}

		console.log('trackAnalyticsEvent', eventTrigger, api, properties);
	}


	$(document).on(
		"click",
		"a[data-behavior~=track-event], button[data-behavior~=track-event], [tabindex][data-behavior~=track-event]",
		function (e) {
			trackEvent(e);
		}
	);

}

export function trackEvent(e) {
	const $target = $(e.currentTarget);
	const events = $target.data("event").split(",");
	events.forEach(function (event) {
		trackAnalyticsEvent({ event, target: e.currentTarget })
	});
}