export function initAutoComplete() {
    const autocompletes = document.querySelectorAll('[data-autocomplete]');

    autocompletes.forEach((autocomplete) => {
        setupAutoComplete(autocomplete);
    });
}

function setupAutoComplete(autocomplete) {
    const input = autocomplete.querySelector('[data-autocomplete-input]');
    const dropdown = autocomplete.querySelector('[data-autocomplete-dropdown]');

    if(!dropdown || !input) {
        return;
    }

    input.addEventListener('input', (e) => {
        const type = autocomplete.dataset.autocomplete;
        const term = e.target.value

        if(term.length >= 3) {
            getResults(type, term, dropdown);
        }
        else {
            dropdown.classList.remove('is-open');
            dropdown.innerHTML = '';
        }
    });

    input.addEventListener('focus', (e) => {
        if(dropdown.querySelectorAll('.dropdown-item').length) {
            dropdown.classList.add('is-open');
        }
    });

    input.addEventListener('blur', (e) => {
        window.setTimeout(() => {
            dropdown.classList.remove('is-open');
        }, 200);
    });
}

function getResults(type, term, dropdown) {
    fetch(`/wp-json/search/${type}/${term}`)
        .then(response => response.json())
        .then(data => updateDropdown(data, term, dropdown));
}

function updateDropdown(data, term, dropdown) {
    if(!data?.length) {
        dropdown.classList.remove('is-open');
        return;
    }

    dropdown.classList.add('is-open');

    let html = '';

    data.forEach((item) => {
        html += `<li class="dropdown-item"><a class="is-black" href="${item.url}">${getHighlightedText(item.name, term)}</a></li>`;
    });

    dropdown.innerHTML = html;
}

function getHighlightedText(name, term) {
    const n = name.toUpperCase();
    const q = term.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
        return name; // bail early
    }
    const l = q.length;
    return name.substr(0, x) + '<b>' + name.substr(x, l) + '</b>' + name.substr(x + l);
}