export function initColorShiftInview() {

	let targets = document.querySelectorAll('.has-two-tone-color-shift');
	if(!targets) return;

	targets.forEach(target => {
		let style = target.getAttribute('style');
		if(style) target.setAttribute('data-style-init', style);
	})

	let page_header = document.querySelector('.page-header.has-two-tone-color-shift');
	let page_hero = document.querySelector('.page-hero.has-two-tone-color-shift');

	if(page_header) {
		document.addEventListener('scroll', () => {
			// Keep header nav and hero transitions combined
			if(window.scrollY > 50) {
				page_header?.classList.add('active');
				page_hero?.classList.add('active');
			}
			else {
				page_header?.classList.remove('active');
				page_hero?.classList.remove('active');
			}
		})
	}


	let threshold = 0;
	let options = {
		rootMargin: '-150px',
		threshold,
	}

	let setInview = (entries, observer) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				entry.target.classList.add('in-view');
				let style = entry.target.getAttribute('data-style-init');
				if(style && page_header) {
					page_header.setAttribute('style', style);
				}
			}
			else {
				entry.target.classList.remove('in-view');
				// If ther are no other in-view targets setting style, then reset page_header to it's data-style-init
				let other_in_view = document.querySelector('.has-two-tone-color-shift.in-view');
				if(other_in_view == null && page_header) {
					page_header.setAttribute('style', page_header.getAttribute('data-style-init'));
				}
			}
		});
	}

	let observer = new IntersectionObserver(setInview, options);

	targets.forEach(target => {
		observer.observe(target);
	});

}
