export function initFloatingLabels() {
	const floatingLabelGroups = document.querySelectorAll('.form-group.floating-label');
	if(floatingLabelGroups.length == 0) return;

	floatingLabelGroups.forEach( floatingLabelGroup => {
		const input = floatingLabelGroup.querySelector('input');
		if(!input) return;

		window.addEventListener('load', ()=> {
			floatingLabelDisplayCheck(input, floatingLabelGroup);
		});

		input.addEventListener('change', ()=> {
			floatingLabelDisplayCheck(input, floatingLabelGroup);
		});

		input.addEventListener('input', ()=> {
			floatingLabelDisplayCheck(input, floatingLabelGroup);
		});

	})
}

function floatingLabelDisplayCheck(el, groupEl, show = false) {
	if(show || el.value.length) {
		groupEl.classList.add('show-label');
	}
	else {
		groupEl.classList.remove('show-label');
	}
}