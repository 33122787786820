export function initFeatureCharts(selector = '[data-feature-chart]') {
    const charts = document.querySelectorAll(selector);

    charts.forEach((chart) => {
        initChart(chart);
    });
}

function initChart(chart) {
    if(!chart) {
        return;
    }

    const toggles = chart.querySelectorAll('[data-chart-product]');
    // console.log(toggles);
    const initialToggle = chart.querySelector('[data-chart-product].open');

    if(initialToggle) {
        clearToggles(toggles, chart);
        activateToggle(initialToggle, chart);
    }

    toggles.forEach((toggle) => {
        toggle.addEventListener('click', (e) => {
            clearToggles(toggles, chart);
            activateToggle(toggle, chart);
        });
    });
}

function clearToggles(toggles, chart) {
    const chartRows = chart.querySelectorAll('[data-chart-supported]');
		const selectedActives = document.querySelectorAll('[data-selected-active]');

		chartRows.forEach((row) => {
        row.classList.add('disabled');

				const supportedClassEls = row.querySelectorAll('[data-supported-class]');
				supportedClassEls.forEach((el) => {
					const classes = el.dataset.supportedClass.split(' ');
					classes.forEach((_class) => {
						el.classList.remove(_class);
					});
				});
    });

		selectedActives.forEach((selectedActive) => {
			selectedActive.classList.remove('active');
		});

    toggles.forEach((toggle) => {
        toggle.classList.remove('open');
    });
}

function activateToggle(toggle, chart) {
    const chartRows = chart.querySelectorAll('[data-chart-supported]');
    const product = toggle.dataset.chartProduct;
		const selectedActive = document.querySelector('[data-selected-active="'+product+'"]');

		chart.dataset.selected = product;

    chartRows.forEach((row) => {
        const supportedProducts = row.dataset?.chartSupported.split(',') || [];
        if(supportedProducts.indexOf(product) >= 0) {
            row.classList.remove('disabled');

						const supportedClassEls = row.querySelectorAll('[data-supported-class]');
						supportedClassEls.forEach((el) => {
							const classes = el.dataset.supportedClass.split(' ');
							classes.forEach((_class) => {
								el.classList.add(_class);
							});
						});
        }
    });

		if(selectedActive) selectedActive.classList.add('active');

    toggle.classList.add('open');
}

export function displayCompetitor(targetEl, containerId, competitorId) {

	const container = document.querySelector('#'+containerId);

	const activeButton = container.querySelector('.comparison-button.button-active');
	activeButton.classList.remove('button-active');
	targetEl.classList.add('button-active');

	const displayActives = container.querySelectorAll('.display-active');
	displayActives.forEach((displayActive) => {
		displayActive.classList.remove('display-active');
	});

	const selectedDisplays = container.querySelectorAll('.display-'+competitorId);
	selectedDisplays.forEach((selectedDisplay) => {
		selectedDisplay.classList.add('display-active');
	});

	setTimeout(function() {glideGoToSlide('carousel-'+containerId, competitorId);}, 200);

}