export function initPriceToggles(selector = '[data-price-toggle]') {
    const priceToggles = document.querySelectorAll(selector);
    priceToggles.forEach((priceToggle) => {
        priceToggle.checked = false; 
        priceToggle.addEventListener('change', (e) => {
            const isOn = e.target.checked;
            console.log(isOn ? e.target.value : e.target.dataset.priceToggle)
            showPrice(isOn ? e.target.value : e.target.dataset.priceToggle);
        });

        showPrice(priceToggle.checked ? priceToggle.value : priceToggle.dataset.priceToggle);
    });
}


function showPrice(showId) {
    const priceDisplays = document.querySelectorAll('[data-price-display]');
    priceDisplays.forEach((priceDisplay) => {
        priceDisplay.classList.add('is-shown');
        
        if(priceDisplay.dataset.priceDisplay === showId) {
            priceDisplay.classList.add('is-visible');
        }
        else {
            priceDisplay.classList.remove('is-visible');
        }
    });
}