// [data-sticky-toggle] is the offsetTop value to trigger the toggle
// [data-sticky-toggle-class] is the classnames to be add/remove'd
const sidebars = document.querySelectorAll('[data-sticky-toggle]');

if(sidebars.length) {
	sidebars.forEach((sidebar) => {
		if(sidebar.dataset.stickyToggleClass) {
			stickyToggleHandler(sidebar);
			document.addEventListener('scroll', (e) => {
				stickyToggleHandler(sidebar);
			})
		}
	})
}

function stickyToggleHandler(sidebar) {
	let classes = sidebar.dataset.stickyToggleClass.split(' ');
	if(sidebar.offsetTop > sidebar.dataset.stickyToggle) {
		sidebar.classList.add(...classes);
	}
	else {
		sidebar.classList.remove(...classes);
	}
}