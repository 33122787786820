/**
 * Grabs the [data-sticky-scroll-tab-list] element, and dynamically adds listeners to both the anchor links,
 * and those anchor link's target elements.
 *
 * Uses scrollToByQuerySelector on the anchor links to animate the scroll action to the anchor link's target elements.
 *
 * Adds a [data-inview-action] to the target element, that adds and removes 'active-tab' to the original anchor links based on whether
 * or not the element is in the center of the page view port.
 */


function handleTabTargetInview(tab_id) {
	const tab = document.getElementById(tab_id);
	removeAllClassnamesThenAdd('active-tab', tab, 100);
}

export default function initStickyScrollTabList() {

	const tab_list = document.querySelector('[data-sticky-scroll-tab-list]');
	if(!tab_list) return;

	window.handleTabTargetInview = handleTabTargetInview;
	window.removeAllClassnamesThenAdd = removeAllClassnamesThenAdd;

	const tab_links = tab_list.querySelectorAll('a');

	const url_anchor = (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : '';

	tab_links.forEach(tab_link => {
		const target_id = tab_link.getAttribute('href'); // Should be HTML #<id> attribute of target element
		if(!target_id) return;
		const target = document.querySelector(target_id);
		if(!target) return;
		const tab_link_anchor = target_id.replace('#', '');
		const tab_link_id = 'tab-link-'+tab_link_anchor;
		tab_link.setAttribute('id', tab_link_id);
		target.setAttribute('data-sticky-scroll-tab-target', true);
		target.setAttribute('data-inview-action', `handleTabTargetInview('${tab_link_id}');`);

		tab_link.addEventListener('click', (e) => {
			scrollToByQuerySelector(target_id);
			removeAllClassnamesThenAdd('active-tab', tab_link, 300);
		});

		// If the current URL has this #<id> as the anchor in it, then fire
		if(url_anchor == tab_link_anchor) {
			scrollToByQuerySelector(target_id);
			removeAllClassnamesThenAdd('active-tab', tab_link, 0);
		}

		// Some mobile browsers register only a touch event on the first tap, and not a click event
		tab_link.addEventListener('touchend', (e) => {

			// If window.scrollIgnore is not undefined, then a touch event has occurred already
			if(window.scrollIgnore !== undefined ) return;

			setTimeout(function(){
				scrollToByQuerySelector(target_id);
				removeAllClassnamesThenAdd('active-tab', tab_link, 300);
			}, 50);
		});

	});


	// Look for if any of our target elements is in the center of the page view port
	document.addEventListener('scroll', (e) => {
		if(window.scrollIgnore && window.scrollIgnore == true) return;

		// Get the offset of the sticky headers
		const page_header = document.querySelector('header.page-header');
		const header_sticky = document.querySelector('.is-page-header-sticky');
		const nav_header_offset = page_header ? page_header.offsetHeight : 0;
		const sticky_offset = header_sticky ? header_sticky.offsetHeight : 0;
		const padding = 50;

		const view_port_start = nav_header_offset + sticky_offset + padding;

		const targets = document.querySelectorAll('[data-sticky-scroll-tab-target]');
		const inview_el = document.elementFromPoint(window.innerWidth/2, view_port_start);

		targets.forEach(target => {
			// If this element is inview...
			if(target.contains(inview_el) || target == inview_el) {
				if(target.dataset.inviewAction) {
					// ...do the element's [data-inview-action]
					eval(target.dataset.inviewAction);
				} else {
					console.warn('The target element missing [data-inview-action] attribute', target);
				}
			}
		});
	});

	return;
}