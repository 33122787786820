// import debounce from 'lodash.debounce';
import imagesLoaded  from 'images-loaded';

export function setupLogoCarousels(supressEvents = false) {
    const carousels = document.querySelectorAll('[data-logo-carousel]');
    carousels.forEach((carousel) => {
        if(carousel.dataset.logoCarousel !== 'static') {
            imagesLoaded(carousel).then(() => {
                setupLogoCarousel(carousel);
            });
        }
        else {
            carousel.classList.add('is-ready');
        }
    });

    // const debouncedSetup = debounce(() => {
    //     setupLogoCarousels(true);
    // }, 250);

    // if(!supressEvents) {
    //     window.addEventListener('resize', debouncedSetup);
    // }

		// Mobile browsers call 'resize' on scroll to account for browser navigation bar behaviors.
		window.resize_innerWidth = window.innerWidth;

		window.addEventListener('resize', () => {
			// Check for window width resize only
			if(window.resize_innerWidth == window.innerWidth) return;
			window.resize_innerWidth = window.innerWidth;
			setupLogoCarousels();
		});
}

function setupLogoCarousel(carousel) {
    carousel.querySelector('[data-logo-carousel-clone]')?.remove();
    const carouselWidth = carousel.clientWidth;
    const children = Array.prototype.slice.call(carousel?.querySelector('.logo-scroller').children);

    carousel.classList.remove('animated');

    const childrenWidth = children?.reduce((width, child) => {
        const styles =  getComputedStyle(child);
        const marginLeft = parseInt(styles.marginLeft);
        const marginRight = parseInt(styles.marginRight);
        return parseInt(width + child.offsetWidth + marginLeft + marginRight);
    }, 0);

    const scroller = carousel?.querySelector('.logo-scroller');
    scroller.style.width = `${childrenWidth}px`;

    const widthThreshold = carousel.dataset.logoCarousel === 'static' ? carouselWidth : carouselWidth / 5;

    if(childrenWidth > widthThreshold) {
        const animationDuration = Math.max(36 * (childrenWidth / 435 ), 1);
        scroller.style.animationDuration = `${animationDuration}s`;
        const clone = scroller.cloneNode(true);
        clone.dataset.logoCarouselClone = true;
        scroller.after(clone);

        window.setTimeout(() => {
            carousel.classList.add('animated');
            carousel.classList.add('is-ready');
        }, 10);
    }
    else {
        carousel.classList.add('is-ready');
    }

}