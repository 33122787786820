export function initSignupFormUpdated() {

	const signupFormBlocks = document.querySelectorAll('.signup-form-updated-block');

	signupFormBlocks.forEach( signupFormBlock => {
		setupSignupFormUpdatedBlock( signupFormBlock );
	});
}

function setupSignupFormUpdatedBlock( signupFormBlock ) {
	setupFeedback( signupFormBlock );

	const signupForm = signupFormBlock.querySelector('form');
	if(signupForm) {
		validationCheck( signupForm );
		signupForm.addEventListener('click', () => {
			validationCheck( signupForm );
		});
	}
}

function setupFeedback( signupFormBlock ) {

	const passwordFeedback = signupFormBlock.querySelector('.password-feedback');
	if(passwordFeedback) {
		const fieldID = passwordFeedback.dataset.for;
		if(!fieldID) return;
		const field = document.getElementById(fieldID);
		if(!field) return;

		field.addEventListener('keyup', () => {
			passwordFeedback.classList.remove('hidden');

			const length = passwordFeedback.querySelector('#length');
			if(length) {
				if(field.value.length >= 8) {
					length.classList.remove('invalid');
					length.classList.add('valid');
				} else {
					length.classList.remove('valid');
					length.classList.add('invalid');
				}
			}

		});
	}
}

function validationCheck( signupForm ) {
	let valid = signupForm.checkValidity();
	if(valid) {
		signupForm.classList.remove('invalid');
		signupForm.classList.add('valid');
	} else {
		signupForm.classList.remove('valid');
		signupForm.classList.add('invalid');
	}
}