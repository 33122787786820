let mobile_dropdowns = document.querySelectorAll('[mobile-dropdown]');
let mobile_dropdown_toggles = document.querySelectorAll('[data-toggle="mobile-dropdown"]');
let body = document.querySelector('body');

if (mobile_dropdowns.length && mobile_dropdown_toggles.length) {
    mobile_dropdowns.forEach((mobile_dropdown) => {
        mobile_dropdown.classList.remove('is-open');

        mobile_dropdown.addEventListener('click', (e) => {
            if(e.target.matches('.mobile-dropdown-list a')) {
							setTimeout(()=>{
                mobile_dropdown.classList.remove('is-open');
							}, 100);
            }
        });
    });

    mobile_dropdown_toggles.forEach(toggle => {
        toggle.addEventListener('click', e => {
            e.preventDefault();

            let dropdown = toggle.closest('[mobile-dropdown]');

            mobile_dropdowns.forEach(other_dropdown => {
                if (other_dropdown === dropdown) return false;
                other_dropdown.classList.remove('is-open');
            });

            dropdown.classList.toggle('is-open');
        });
    });

    mobile_dropdowns.forEach(dropdown => {
        dropdown.addEventListener('click', e => {
            e.stopPropagation();
        });
    });

    body.addEventListener('click', e => {
        mobile_dropdowns.forEach(dropdown => {
            dropdown.classList.remove('is-open');
        });
    })
}