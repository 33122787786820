window.toggleFeatureComparison = toggleFeatureComparison;
window.addEventListener('resize', initToggleFeatureComparison);

export function initToggleFeatureComparison() {

	const blocks = document.querySelectorAll('.toggle-feature-comparison');
	if(!blocks || !blocks.length) return;
	blocks.forEach(block => {
		const feature_sets = block.querySelectorAll('[data-feature-set-id]');

		// Allow containers to be natural offset height
		let min_height = 820;
		feature_sets.forEach(feature_set => {
			feature_set.style.minHeight = min_height+'px';
			feature_set.classList.add('calc-height');
		});

		// Give time to redraw
		setTimeout(()=> {

			// Find tallest container
			feature_sets.forEach(feature_set => {
				if(feature_set.offsetHeight > min_height) min_height = feature_set.offsetHeight;
			});

			// Set new min-height if taller containers found
			if(min_height > 820) {
				feature_sets.forEach(feature_set => {
					feature_set.style.minHeight = min_height+'px';
				});
			}

		}, 10);

		// Reset style
		setTimeout(()=> {
			feature_sets.forEach(feature_set => {
				feature_set.classList.remove('calc-height');
			});
		}, 15);
	});

	if(window['toggleFeatureComparisonInit'] == undefined) {
		window['toggleFeatureComparisonInit'] = true;
		// Check for if HTML anchor link is in URL, and check for toggle button, then scoll to feature
		if(document.location.hash == '') return;
		let anchoredFeature = document.querySelector('.toggle-feature-comparison [data-html-anchor="'+document.location.hash+'"]');
		if(!anchoredFeature) return;
		// Find and click the toggle-button with the current layout_option, if applicable
		let toggleButton = anchoredFeature.querySelector('.toggle-button[data-toggle-index="'+anchoredFeature.dataset.featureSetIndex+'"]');
		toggleButton?.click();

		scrollToByQuerySelector('.toggle-feature-comparison [data-html-anchor="'+document.location.hash+'"]');
	}
}

function toggleFeatureComparison(toggle_id, button_el = null, toggle_index = 0) {
	if(button_el && button_el.classList.contains('active')) return;
	const containers = document.querySelectorAll('[data-toggle-id="'+toggle_id+'"]');
	if(!containers || !containers.length) return;
	// Synchronize the toggle buttons in each container
	containers.forEach(container => {
		const toggle_buttons = container.querySelectorAll('.toggle-button');
		toggle_buttons.forEach(toggle_button => {
			if(toggle_button.dataset.toggleIndex == toggle_index) toggle_button.classList.add('active');
			else toggle_button.classList.remove('active');
		});
	});
	const feature_sets = document.querySelectorAll('[data-feature-set-id="'+toggle_id+'"]');
	feature_sets.forEach(feature_set => {
		if(feature_set.dataset.featureSetIndex == toggle_index) feature_set.classList.add('active');
		else feature_set.classList.remove('active');
	});
}
