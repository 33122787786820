
export default function setupStaticCoverageMap() {

	const staticCoverageMapBlock = document.querySelector('.static-coverage-map');

	if(staticCoverageMapBlock == undefined) return;

	window.simulateZipSubmitOnGeocoder = simulateZipSubmitOnGeocoder;

	// Setup listeners
	const map_search_form = document.getElementById('static-coverage-map__form');
	map_search_form.addEventListener('submit', (event) => {
		event.preventDefault();
		simulateZipSubmitOnGeocoder();
	});

	// "Skip to details" links are inserted dynamically, so we monitor for them with setInterval.
	window.skipToContentFixerInterval = setInterval(skipToContentFixer, 500);

}

function simulateZipSubmitOnGeocoder() {
	const geocoder = document.getElementById('geocoder');
	if(!geocoder) return;


	const map_search_zip = document.getElementById('static-coverage-map__zip');
	const zip = map_search_zip.value;
	const li = document.createElement('li');
	const div = document.createElement('div');

	setTimeout(() => {
		// Simulate a zip being searched
		li.classList.add('active');
		div.classList.add('mapboxgl-ctrl-geocoder--suggestion-title');
		div.innerText = zip;
		li.appendChild(div);
		geocoder.querySelector('.suggestions').appendChild(li);
		geocoder.querySelector('input').value = zip;
		geocoder.parentElement.querySelector('button').click();
	}, 500);


	setTimeout(() => {
		// Simulates selecting the location, which sets the map view and gets the results
		const locationMarker = document.querySelector('.mapboxgl-user-location-dot.mapboxgl-marker.mapboxgl-marker-anchor-center');
		if(locationMarker) locationMarker.click();
	}, 750);

	setTimeout(() => {
		// Pretty up the UI
		geocoder.querySelector('.suggestions').style.display = 'none';
		geocoder.querySelector('input').value = zip;

		// Map will re-adjust it's viewport on resize
		window.dispatchEvent(new Event('resize'));

		const modal_embed = document.getElementById('static-coverage-map__modal-embed');
		if(modal_embed) modal_embed.focus();
	}, 1000);
}

/**
 * "Skip to details" links scroll the document body, but it is in a modal, so this will
 * override that, by disabling the link, and placing a "click" listener on the
 * parent container to scroll the modal content, instead of the body.
 */
function skipToContentFixer() {

	let maptool = document.getElementsByTagName('tmo-maptool-element');
	if(maptool[0] == undefined ) return;
	let skipButton = maptool[0].querySelector('.skip-content');
	if(skipButton == undefined) return;

	// Disable the link
	skipButton.classList.add('fixed');
	skipButton.style.pointerEvents = 'none';
	skipButton.setAttribute('tabindex', '-1');

	// Turn the parent container into a link
	let skipContainer = document.querySelector('.skip-content-container');
	skipContainer.setAttribute('tabindex', '0');
	skipContainer.setAttribute('role', 'link');
	skipContainer.setAttribute('aria-label', 'Skip to details');
	skipContainer.style.cursor = 'pointer';

	skipContainer.addEventListener('click', function skipClickOverride() {
		let modalContent = document.querySelector('.static-coverage-map__modal-content');
		modalContent.scroll({
			top: 10000,
			left: 0,
			behavior: "smooth",
		});
	});
}