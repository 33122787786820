export default function initMinimalFadeCarousel() {

	const blocks = document.querySelectorAll('.minimal-fade-carousel');
	if(blocks.length) window.advanceMinimalFadeCarousel = advanceMinimalFadeCarousel;

	blocks.forEach(block => {
		if(! block.id) block.setAttribute('id', 'minimal-fade-carousel-' + Math.floor(Math.random() * 9999));

		const speed = block.dataset.speed ?? 5000;

		const progressBar = block.querySelector('.minimal-fade-carousel-progress-bar');
		if(speed != 0) progressBar.classList.add('active');

		const buttons = block.querySelectorAll('.controls-button');
		buttons.forEach(button => {
			button.addEventListener('click', (event) => {
				event.target.classList.add('clicked');
				setTimeout(() => {
					event.target.blur();
				}, 500);
			});
		});

		if(speed != 0) { // 0 to turn off autoplay
			window[block.id] = setTimeout(() => {
				advanceMinimalFadeCarousel(block.id, 'next', speed);
			}, speed);
		}

		setMinimalFadeCarouselContainerHeight(block.id, 1);
		window.addEventListener('resize', () => {
			setMinimalFadeCarouselContainerHeight(block.id);
		});
	});

}

function advanceMinimalFadeCarousel(id, goTo, timeout = 0) {

	const block = document.getElementById(id);
	if(!block) return;

	clearTimeout( window[id] );

	const selectorBase = '#'+id+'-';
	const activeSlide = block.querySelector('.minimal-fade-carousel-slide.active');
	const progressBar = block.querySelector('.minimal-fade-carousel-progress-bar');

	progressBar.classList.remove('active');

	if(!activeSlide) {
		block.querySelector(selectorBase+'0').classList.add('active');
	} else  {
		activeSlide.classList.remove('active');
		const currentIndex = Number.parseInt(activeSlide.dataset.index);

		if(goTo == 'next') {
			const nextSlide = block.querySelector(selectorBase + (currentIndex + 1));
			if(nextSlide) {
				nextSlide.classList.add('active');
			} else { // Go to start
				block.querySelector(selectorBase+'0').classList.add('active');
			}
		} else if(goTo == 'prev') {
			const prevSlide = block.querySelector(selectorBase + (currentIndex - 1));
			if(prevSlide) {
				prevSlide.classList.add('active');
			} else { // Go to end
				block.querySelector(selectorBase + (block.dataset.count - 1)).classList.add('active');
			}
		} else if(Number.isInteger(goTo)) {
			block.querySelector(selectorBase+goTo).classList.add('active');
		} else {
			block.querySelector(selectorBase+'0').classList.add('active');
		}
	}

	setTimeout(() => {
		const newActiveSlide = block.querySelector('.minimal-fade-carousel-slide.active');
		const newCurrentIndex = Number.parseInt(newActiveSlide.dataset.index);
		const counter = block.querySelector('.counter-target');
		counter.innerHTML = (newCurrentIndex + 1 < 10) ? '0'+(newCurrentIndex + 1) : (newCurrentIndex + 1);

		if(timeout) {
			progressBar.classList.add('active');
		} else {
			let width = Math.floor((100 / block.dataset.count) * (newCurrentIndex + 1));
			progressBar.style.width = width+'%';
		}
	}, 20);

	if(timeout) {
		window[id] = setTimeout(() => {
			advanceMinimalFadeCarousel(id, 'next', timeout);
		}, timeout);
	}

	setMinimalFadeCarouselContainerHeight(id);

}

function setMinimalFadeCarouselContainerHeight(id, pixelBump = 0) {
	const block = document.getElementById(id);
	if(!block) return;
	let maxHeight = 0;
	const container = block.querySelector('.minimal-fade-carousel-container');
	const slides = block.querySelectorAll('.minimal-fade-carousel-slide');
	slides.forEach( slide => {
		if(slide.offsetHeight > maxHeight) maxHeight = slide.offsetHeight;
	});

	if(maxHeight) container.style.minHeight = (maxHeight+pixelBump)+'px';
}