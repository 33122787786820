export default function initHeaderNav() {
    document.body.addEventListener('click', toggleNav);
}

function toggleNav(e) {
    if(!e.target.dataset.toggleNav) {
        return;
    }

    const nav = document.querySelector('[data-main-nav]');
    nav?.classList.toggle('show-nav');
		const nav_buttons = document.querySelector('[data-nav-buttons]');
    nav_buttons?.classList.toggle('show-nav');
		document.body.classList.toggle('nav-open');
		e.target.classList.toggle('nav-open');

    if(nav?.classList.contains('show-nav')) {
        document.documentElement.classList.add('scroll-locked');
    }
    else {
        document.documentElement.classList.remove('scroll-locked');
    }

}